import {Injectable} from '@angular/core';
import {ApolloError} from '@apollo/client/core';
import { GraphQLError } from "graphql/error";
import { ErrorCode } from "../graphql/generated/graphql";

interface ErrorCodeMapping {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorCodeService {

  constructor() { }

  private errorCodeMapping: ErrorCodeMapping = {
    ['PLAYER_ALREADY_ONBOARDED']: $localize `You are already onboarded. Please refresh the page.`,
  };

  getErrorCode(error: ApolloError | GraphQLError | Record<string, any>) {
    if (error instanceof ApolloError) {
      const code = error.graphQLErrors[0]?.extensions['code'];

      return code as string;
    }
    if (error instanceof GraphQLError) {
      const code = error.extensions?.['code'];
      return code as string;
    }
    // if object has code property
    if (error.hasOwnProperty('extensions') && error['extensions'].hasOwnProperty('code')) {
      const code = error['extensions']['code'];
      return code as string;
    }
    return $localize `An error occurred, please try again later. If the problem persists, please contact support.`;
  }

  private errorCodeToMessageMap: Record<string, string> = {
    [ErrorCode.BackofficeUserNotConnectedToUser]: $localize `Your Manager-Account is not linked to an app account, please contact support.`,
    [ErrorCode.CancellationReasonRequired]: $localize `Please provide a reason for the cancellation.`,
    [ErrorCode.CashPaymentNotAvailable]: $localize `Cash payment is not available.`,
    [ErrorCode.ChangesRequired]: $localize `No changes detected.`,
    [ErrorCode.ClubNotFound]: $localize `Club not found.`,
    [ErrorCode.EventAlreadyStarted]: $localize `Event has already started.`,
    [ErrorCode.EventIsFree]: $localize `Event is free.`,
    [ErrorCode.EventIsFull]: $localize `Event is already full.`,
    [ErrorCode.EventNotFound]: $localize `Event not found.`,
    [ErrorCode.EventNotFree]: $localize `Event is not free.`,
    [ErrorCode.EventNotPayableByCash]: $localize `Event is not payable by cash.`,
    [ErrorCode.EventRequired]: $localize `An Event is required.`,
    [ErrorCode.EventSportNotOnboarded]: $localize `Event sport is not onboarded.`,
    [ErrorCode.GuestBookingNotAllowed]: $localize `Guest booking is not allowed.`,
    [ErrorCode.ExpirationDateInPast]: $localize `Expiration date is in the past.`,
    [ErrorCode.MaxUsersTooLow]: $localize `Maximum number of players is too low.`,
    [ErrorCode.MissingData]: $localize `Required data is missing.`,
    [ErrorCode.NotFound]: $localize `Not found.`,
    [ErrorCode.QuantityTooHigh]: $localize `Quantity is too high.`,
    [ErrorCode.QuantityTooLow]: $localize `Quantity is too low.`,
    [ErrorCode.RangeThresholdExceeded]: $localize `Range threshold exceeded.`,
    [ErrorCode.RangeTooLarge]: $localize `Range is too large.`,
    [ErrorCode.RefundFailed]: $localize `Refund failed.`,
    [ErrorCode.ReservationNotFound]: $localize `Reservation not found.`,
    [ErrorCode.ReservationOverlapping]: $localize `Reservation is overlaps with another reservation.`,
    [ErrorCode.StartTimeAnExpirationDateNotSet]: $localize `Start time and expiration date are not set.`,
    [ErrorCode.StartTimeInPast]: $localize `Start time is in the past.`,
    [ErrorCode.Unauthorized]: $localize `Unauthorized.`,
    [ErrorCode.UnknownActivityType]: $localize `Unknown activity type.`,
    [ErrorCode.ZipCodeNotFound]: $localize `Zip code not found.`,
    [ErrorCode.InvalidCredentials]: $localize `Email or password is incorrect.`,
  }

  getErrorMessageFromCode(code: string | null): string {
    if (code && this.errorCodeToMessageMap[code]) {
      return this.errorCodeToMessageMap[code];
    }
    let message = 'An error occurred. Please try again later. If the problem persists, please contact support.';
    if (code) {
      message = message + '<br>Code: ' + code;
    }
    return message;
  }

  formatErrorToMessage(error: any): string {
    if (!error) {
      return this.getErrorMessageFromCode(error);
    } else if (typeof error === 'object' && error.hasOwnProperty('message')) {
      return error.message;
    } else if (typeof error === 'string') {
      return error;
    } else if (error instanceof ApolloError) {
      return this.getErrorMessageFromCode(this.getErrorCode(error));
    } else if (error instanceof GraphQLError) {
      return this.getErrorMessageFromCode(this.getErrorCode(error));
    } else {
      return this.getErrorMessageFromCode(null);
    }
  }
}
